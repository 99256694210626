import React, { useEffect, useState } from "react";
import {
  IoPencil,
  IoSyncOutline,
  IoCloseOutline,
  IoEyeOutline,
  IoEyeOffOutline,
  IoPrintOutline,
} from "react-icons/io5";
import {
  deleteEmployee,
  getAllEmployees,
  selectAllEmployees,
  addEmployees,
  editEmployees,
  selectEmployeeCount,
  getEmployeeHistory,
} from "../../redux/features/employeesSlice";
import {
  getDepartmentNames,
  selectDepartmentNames,
} from "../../redux/features/departmentSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../assets/defaultImg.png";
import Randomstring from "randomstring";
import {
  newEmployeeSchema,
  editEmployeeSchema,
} from "../../validation-schemas/schemas";
import { useStateContext } from "../../components/contexts/ContextProvider";
import { RotatingLinesLoader } from "../../components/Loaders";
import { EditModal, DeleteModal } from "../../components/Modals";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BarsLoader } from "../../components/Loaders";
import { genPdf } from "../pdf/GeneratePdf";

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { depId } = useParams();

  const employees = useSelector(selectAllEmployees);
  const departmentNames = useSelector(selectDepartmentNames);
  const count = useSelector(selectEmployeeCount);
  const employeeLoading = useSelector((state) => state.employees.loading);

  const newDate = new Date();
  const formattedDate = moment(newDate).format("YYYY-MM-DD");

  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Employee");
  const [dept_id, setDept_id] = useState(depId ? depId : "");
  const [employeeStatus, setEmployeeStatus] = useState("WORKING");
  const [canGeneratePass, setCanGeneratePass] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "employee_name", // default sort key
    direction: "asc", // default sort direction
  });

  const [, setEmployeeHistory] = useState([]);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
    portal,
  } = useStateContext();

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    phone_number: "",
    national_id_number: "",
    emergency_contact_number: "",
    gender: "",
    department: "",
    designation: "",
    bank: "",
    account_number: "",
    total_leaves: "",
    profile_pic: "",
    joining_date: formattedDate,
    employee_status: "",
    team_lead: "",
    is_verified: true,
    is_active: true,
    uidb64: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    setIsInitialLoad(false);
    dispatch(getDepartmentNames({}));
  }, []);

  const dispatchEmployees = () => {
    dispatch(
      getAllEmployees({
        employee_status: employeeStatus,
        dept_id,
        searchData,
        toast,
        navigate,
        page_size: perPage * page,
        page: 1,
        rowChange,
        filterChange,
        portal,
      })
    );
  };

  const filterUseEffect = () => {
    if (filterChange) {
      dispatchEmployees();
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (employees.length < count) {
      dispatchEmployees();
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      employees.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getAllEmployees({
          employee_status: employeeStatus,
          dept_id,
          searchData,
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          portal,
          rowChange: true, //there is no change in row in this case...this is because  when user jump in the last page then all data loads in slice
        })
      );
    } else {
      if (
        (employees.length < page * perPage &&
          (!employees.length || employees.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getAllEmployees({
            employee_status: employeeStatus,
            dept_id,
            searchData,
            toast,
            navigate,
            page_size: perPage,
            page,
            rowChange,
            filterChange,
            isInitialLoad,
            portal,
          })
        );
      }
    }
  };

  const handleGeneratePdf = async (row) => {
    try {
      const historyResponse = await dispatch(
        getEmployeeHistory({ emp_id: row.id })
      ).unwrap();
      if (historyResponse && historyResponse.results.length > 0) {
        setEmployeeHistory(historyResponse.results);
        genPdf("download", historyResponse.results, row);
      } else {
        genPdf("download", [], row);
      }
    } catch (error) {
      console.error("Error fetching employee history:", error);
    }
  };
  const handleViewPdf = async (row) => {
    try {
      const historyResponse = await dispatch(
        getEmployeeHistory({ emp_id: row.id })
      ).unwrap();
      if (historyResponse && historyResponse.results.length > 0) {
        setEmployeeHistory(historyResponse.results);
        genPdf("view", historyResponse.results, row);
      } else {
        genPdf("view", [], row);
      }
    } catch (error) {
      console.error("Error fetching employee history:", error);
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Profile</div>,
      cell: (row) => (
        <div className="grid place-content-center w-full">
          <img
            src={row.profile_pic === null ? defaultImg : row.profile_pic}
            alt="profile pic"
            className="mx-1 my-2 rounded-full w-12 h-12"
          ></img>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Name</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.employee_name}
        </div>
      ),
      selector: (row) => row.employee_name,
      sortable: true,

      sortKey: "employee_name",
      onClick: () => handleSort("employee_name"),
    },
    {
      name: <div className="text-center w-full text-slate-600">Email</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.email}</div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Phone Number</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.phone_number}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Designation</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {toTitleCase(row.designation)}
        </div>
      ),
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Department</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.department}
        </div>
      ),
      selector: (row) => row.department,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Team Lead</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.team_lead_name ? row.team_lead_name : "-"}
        </div>
      ),
      selector: (row) => row.team_lead_name,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Employment Status
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.employee_status === "WORKING"
                ? "bg-emerald-600"
                : row.employee_status === "FIRED"
                ? "bg-red-600"
                : "bg-slate-600"
            }`}
          >
            {toTitleCase(row.employee_status)}
          </span>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Details</div>,
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <NavLink
            to={`/employee/${row.id}`}
            className="cursor-pointer w-10 py-2 flex items-center justify-center bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
          >
            <IoEyeOutline className="w-5 h-5 text-inherit text-slate-50" />
          </NavLink>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            {portal === "admin" ? (
              <button
                className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                onClick={() => {
                  handleEdit(row);
                  setDeleteId(row.id);
                }}
              >
                <IoPencil className="w-5 h-5 text-inherit" />
              </button>
            ) : null}
            <button
              className="cursor-pointer  p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                handleGeneratePdf(row);
              }}
            >
              <IoPrintOutline className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer  p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                handleViewPdf(row);
              }}
            >
              <IoEyeOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const toTitleCase = (str) => {
    if (!str || typeof str !== "string") {
      return ""; // Return an empty string or handle the case as needed
    }
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleDelete = () => {
    dispatch(
      deleteEmployee({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profile_pic") {
      const selectedFile = files[0];
      setFormValues({ ...formValues, profile_pic: selectedFile });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const generatePassword = () => {
    const randomPass = Randomstring.generate(8);
    setFormValues({ ...formValues, password: randomPass });
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formValues.uidb64 === "" ||
      formValues.uidb64 === null ||
      formValues.uidb64 === undefined
    ) {
      try {
        const updatedValues = {
          ...formValues,
          email: formValues.email.toLowerCase(),
        };
        try {
          await newEmployeeSchema.validate(formValues, { abortEarly: false });
          dispatch(
            addEmployees({
              formValues: { ...updatedValues },
              toast,
              navigate,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add employee", err);
      }
    } else {
      try {
        try {
          await editEmployeeSchema.validate(formValues, { abortEarly: false });
          const { uidb64, profile_pic, ...updatedFormValues } = formValues;
          if (typeof profile_pic !== "object") {
            dispatch(
              editEmployees({
                uidb64,
                formValues: updatedFormValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          } else {
            dispatch(
              editEmployees({
                uidb64,
                formValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          }
          setFormValues({
            uidb64: "",
            profile_pic: null,
            team_lead: "",
          });
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update employee", err);
      }
    }
  };

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Employee");
    openEditModal();
    setCanGeneratePass(true);
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      department: row.department_id,
      uidb64: row.id,
      profile_pic: row.profile_pic ? row.profile_pic : "",
    });

    setErrors({});
    setHeading("Update Employee");
    openEditModal();
    setCanGeneratePass(false);
    setClicked(false);
  };
  const filterData = () => {
    setSearchData(searchQuery);
    setFilterChange(true);
    setIsFilterApplied(true);
  };

  const ClearFilter = () => {
    setEmployeeStatus("WORKING");
    dispatch(
      getAllEmployees({
        employee_status: "WORKING",
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
        portal,
      })
    );
    setSearchQuery("");
    setSearchData("");
    setDept_id("");
    setIsFilterApplied(false);
  };

  return (
    <div>
      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">First Name</label>
                  <input
                    value={formValues.first_name}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="first_name"
                  />
                  {errors.first_name && (
                    <p className="form-errors">{errors.first_name}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Last Name</label>
                  <input
                    value={formValues.last_name}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="last_name"
                  />
                  {errors.last_name && (
                    <p className="form-errors">{errors.last_name}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Username</label>
                  <input
                    value={formValues.username}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="username"
                  />
                  {errors.username && (
                    <p className="form-errors">{errors.username}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Email</label>
                  <input
                    value={formValues.email}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="email"
                    name="email"
                  />
                  {errors.email && (
                    <p className="form-errors">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Phone Number
                  </label>
                  <input
                    value={formValues.phone_number}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="phone_number"
                  />
                  {errors.phone_number && (
                    <p className="form-errors">{errors.phone_number}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    National ID Number
                  </label>
                  <input
                    value={formValues.national_id_number}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="national_id_number"
                  />
                  {errors.national_id_number && (
                    <p className="form-errors">{errors.national_id_number}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Emergency Contact Number
                  </label>
                  <input
                    value={formValues.emergency_contact_number}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="emergency_contact_number"
                  />
                  {errors.emergency_contact_number && (
                    <p className="form-errors">
                      {errors.emergency_contact_number}
                    </p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Gender</label>
                  <select
                    value={formValues.gender}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="gender"
                  >
                    <option value="">Select Gender</option>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                  {errors.gender && (
                    <p className="form-errors">{errors.gender}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Department</label>
                  <select
                    onChange={handleChange}
                    value={formValues.department}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="department"
                  >
                    <option value="">Select Department</option>
                    {departmentNames
                      ? departmentNames.map((val, index) => (
                          <option key={index} value={val.id}>
                            {val.department_name}
                          </option>
                        ))
                      : null}
                  </select>
                  {errors.departmentNames && (
                    <p className="form-errors">{errors.departmentNames}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Designation
                  </label>
                  <select
                    value={formValues.designation}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="designation"
                  >
                    <option value="">Select Designation</option>
                    <option value="INTERN">Intern</option>
                    <option value="ASSOCIATE SOFTWARE ENGINEER">
                      Associate Software Engineer
                    </option>
                    <option value="SOFTWARE ENGINEER">Software Engineer</option>
                    <option value="SENIOR SOFTWARE ENGINEER">
                      Senior Software Engineer
                    </option>
                    <option value="FULL STACK DEVELOPER">
                      Full Stack Developer
                    </option>
                    <option value="BUSINESS DEVELOPER EXECUTIVE">
                      Business Developer Executive
                    </option>
                    <option value="BUSINESS DEVELOPMENT MANAGER">
                      Business Development Manager
                    </option>
                    <option value="ACCOUNTS & ADMIN EXECUTIVE">
                      Accounts & Admin Executive
                    </option>
                    <option value="HR MANAGER">HR Manager</option>
                  </select>
                  {errors.designation && (
                    <p className="form-errors">{errors.designation}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Bank Branch
                  </label>
                  <input
                    value={formValues.bank}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="bank"
                  />
                  {errors.bank && <p className="form-errors">{errors.bank}</p>}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Bank Account Number
                  </label>
                  <input
                    value={formValues.account_number}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="account_number"
                  />
                  {errors.account_number && (
                    <p className="form-errors">{errors.account_number}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Profile Picture
                  </label>
                  <input
                    defaultValue={formValues.profile_pic}
                    onChange={handleChange}
                    className="w-full px-2 py-1.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="file"
                    accept="image/*"
                    name="profile_pic"
                  />
                  {errors.profile_pic && (
                    <p className="form-errors">{errors.profile_pic}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Joining Date
                  </label>
                  <input
                    value={formValues.joining_date}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="date"
                    name="joining_date"
                    max={formValues.joining_date}
                  />
                  {errors.joining_date && (
                    <p className="form-errors">{errors.joining_date}</p>
                  )}
                </div>
                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Allocated Leaves
                  </label>
                  <input
                    value={formValues.total_leaves}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="total_leaves"
                  />
                  {errors.total_leaves && (
                    <p className="form-errors">{errors.total_leaves}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Employee Status
                  </label>
                  <select
                    value={formValues.employee_status}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="employee_status"
                  >
                    <option value="">Select Status</option>
                    <option value="WORKING">Working</option>
                    <option value="RESIGNED">Resigned</option>
                    <option value="FIRED">Fired</option>
                  </select>
                  {errors.employee_status && (
                    <p className="form-errors">{errors.employee_status}</p>
                  )}
                </div>

                <div className="relative">
                  <label className="ml-1 mb-1 text-slate-600 flex items-center justify-between">
                    Generate Password{" "}
                    {canGeneratePass ? (
                      <IoSyncOutline
                        className={`cursor-pointer transition-all duration-1000 mr-1 ${
                          clicked ? "rotate-180" : "-rotate-180"
                        }`}
                        onClick={() => generatePassword()}
                      />
                    ) : null}
                  </label>
                  <input
                    value={formValues.password}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md cursor-not-allowed"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    disabled
                  />
                  <div
                    className="absolute bottom-0 right-2 text-slate-500 hover:text-slate-600 transition-all duration-300 cursor-pointer h-10 w-10 grid place-content-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <IoEyeOffOutline className=" h-5 w-5 mb-[1px] text-inherit" />
                    ) : (
                      <IoEyeOutline className=" h-5 w-5 mb-[1px] text-inherit" />
                    )}
                  </div>
                  {errors.password && (
                    <p className="form-errors">{errors.password}</p>
                  )}
                </div>
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Team Lead</label>
                  <select
                    value={formValues.team_lead}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="team_lead"
                  >
                    <option value="">Select an employee</option>
                    {employees?.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.employee_name}
                      </option>
                    ))}
                  </select>
                  {errors.team_lead && (
                    <p className="form-errors">{errors.team_lead}</p>
                  )}
                </div>
              </div>
              <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                <div className="flex-grow flex justify-between items-center">
                  <div>
                    <button
                      type="button"
                      className={`px-3 py-2 bg-red-500/70 hover:bg-red-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300 ${
                        formValues.employee_status === "WORKING"
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (
                          formValues.employee_status === "FIRED" ||
                          formValues.employee_status === "RESIGNED"
                        ) {
                          closeEditModal();
                          openDeleteModal();
                        }
                      }}
                      disabled={formValues.employee_status === "WORKING"}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="flex gap-4 justify-end">
                    {enable ? (
                      <button
                        className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                        type="submit"
                      >
                        Save
                      </button>
                    ) : (
                      <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                        <RotatingLinesLoader />
                      </div>
                    )}
                    <button
                      type="button"
                      className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                      onClick={closeEditModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </EditModal>

      <DeleteModal handleDelete={handleDelete} />

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
        <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between flex-wrap">
            <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
              Employees
            </h5>

            <div className="flex md:flex-row gap-2 md:items-center max-md:items-start flex-wrap">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Employees"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="rounded-lg p-2 w-40 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 focus:ring-0 "
                />
              </div>
              <div className="">
                <select
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                  value={employeeStatus}
                  onChange={(e) => {
                    setEmployeeStatus(e.target.value);
                  }}
                >
                  <option value="">All Employees</option>
                  <option value="WORKING">Working</option>
                  <option value="RESIGNED">Resigned</option>
                  <option value="FIRED">Fired</option>
                </select>
              </div>
              <div className="">
                <select
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                  value={dept_id}
                  onChange={(e) => setDept_id(e.target.value)}
                >
                  <option value="">Select Department</option>
                  {departmentNames
                    ? departmentNames.map((val, index) => (
                        <option key={index} value={val.id}>
                          {val.department_name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <button
                type="button"
                className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                onClick={() => filterData()}
              >
                Apply Filter
              </button>
              {isFilterApplied && (
                <div>
                  <button
                    type="button"
                    className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                    onClick={() => ClearFilter()}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Clear Filter
                  </button>
                </div>
              )}
              {portal === "admin" ? (
                <div>
                  <button
                    className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                    onClick={handleNew}
                    style={{ marginLeft: "30px" }}
                  >
                    Add New Employee
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="card-body">
            <Pagination
              data={employees}
              filterChange={filterChange}
              filterUseEffect={filterUseEffect}
              setRowChange={setRowChange}
              rowChangeUseEffect={rowChangeUseEffect}
              pageChangeUseEffect={pageChangeUseEffect}
              isLoading={employeeLoading}
              count={count}
              columns={columns}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
