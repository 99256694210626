import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import {
  getRecruiter,
  addRecruiter,
  deleteRecruiter,
  editRecruiter,
  selectAllRecruits,
  selectRecruitsLoading,
  selectRecruitsCount,
} from "../../redux/features/recruiterSlice";
import {
  getAllEmployeesNames,
  selectEmployeeNames,
} from "../../redux/features/employeesSlice";
import {
  IoPencil,
  IoTrashOutline,
  IoCloseOutline,
  IoEyeOutline,
} from "react-icons/io5";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  newApplicantSchema,
  editApplicantSchema,
} from "../../validation-schemas/schemas";
import { EditModal, DeleteModal } from "../../components/Modals";
import Pagination from "../../components/Pagination";
import moment from "moment";

const Recruits = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employees = useSelector(selectEmployeeNames);
  const count = useSelector(selectRecruitsCount);
  const recruits = useSelector(selectAllRecruits);
  const recruitsLoading = useSelector(selectRecruitsLoading);
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Applicant");
  const [deleteId, setDeleteId] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
    portal,
    profile,
  } = useStateContext();

  const [appliedPosition, setAppliedPosition] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const employeeDetailList = useSelector(selectEmployeeNames);
  const newDate = new Date();
  const formattedDate = moment(newDate).format("YYYY-MM-DD");

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    position: "",
    resume: "",
    status: "",
    referrers: null,
    interview_date: formattedDate,
    assigned_to: "",
    uidb64: "",
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setIsInitialLoad(false);
    dispatch(getAllEmployeesNames({ toast, navigate }));
  }, []);

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getRecruiter({
          position: appliedPosition,
          status: applicationStatus,
          applicant_name: applicantName,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (recruits.length < count) {
      dispatch(
        getRecruiter({
          position: appliedPosition,
          status: applicationStatus,
          applicant_name: applicantName,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      recruits.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getRecruiter({
          position: appliedPosition,
          status: applicationStatus,
          applicant_name: applicantName,
          page_size: perPage * page,
          page: 1,
          rowChange: true, //there is no change in row in this case...this is because  when user jump in the last page then all data loads in slice
        })
      );
    } else {
      if (
        (recruits.length < page * perPage &&
          (!recruits.length || recruits.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getRecruiter({
            position: appliedPosition,
            status: applicationStatus,
            applicant_name: applicantName,
            page_size: perPage,
            page,
            rowChange,
            filterChange,
          })
        );
      }
    }
  };

  const ClearFilter = () => {
    dispatch(
      getRecruiter({
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
      })
    );
    setAppliedPosition("");
    setApplicationStatus("");
    setApplicantName("");
    setIsFilterApplied(false);
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Name</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.first_name.charAt(0).toUpperCase() +
            row.first_name.slice(1) +
            " " +
            row.last_name.charAt(0).toUpperCase() +
            row.last_name.slice(1)}
        </div>
      ),
      selector: (row) => row.first_name,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Email</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.email}</div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Phone Number</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.phone_number}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Position</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {snakeToTitleCase(row.position)}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Resume link</div>
      ),
      cell: (row) => (
        <div className="text-center w-full">
          <a
            href={row.resume}
            className="text-emerald-500/70 hover:text-emerald-500/90"
            target="_blank"
          >
            Open Resume
          </a>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Referrals</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.referrers}</div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Interview Date</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.interview_date}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Assigned To</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.assigned_to ? row.assigned_to.name : "Not Assigned"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.status === "PENDING"
                ? "bg-sky-600"
                : row.status === "IN_PROCESS"
                ? "bg-yellow-500"
                : row.status === "REJECTED"
                ? "bg-red-600"
                : row.status === "SELECTED"
                ? "bg-emerald-600"
                : "bg-slate-600"
            }`}
          >
            {snakeToTitleCase(row.status)}
          </span>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">History</div>,
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <NavLink
            to={`/recruits/${row.id}/${row.full_name}`}
            state={{ row }}
            className="cursor-pointer w-10 py-2 flex items-center justify-center bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
          >
            <IoEyeOutline className="w-5 h-5 text-inherit text-slate-50" />
          </NavLink>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const snakeToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "resume") {
      const selectedFile = files[0];
      setFormValues({ ...formValues, resume: selectedFile });
    } else if (name === "referrers") {
      setFormValues({ ...formValues, referrers: value === "" ? null : value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Applicant");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      referrers: row.referrer_id === undefined ? "" : row.referrer_id,
      assigned_to: row.assigned_to?.id === undefined ? "" : row.assigned_to.id,
      uidb64: row.id,
    });

    setErrors({});
    setHeading("Update Applicant");
    openEditModal();
  };

  const handleDelete = () => {
    dispatch(
      deleteRecruiter({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formValues.uidb64 === "" ||
      formValues.uidb64 === null ||
      formValues.uidb64 === undefined
    ) {
      try {
        try {
          await newApplicantSchema.validate(formValues, { abortEarly: false });
          dispatch(
            addRecruiter({
              formValues,
              toast,
              navigate,
              setEnable,
              closeEditModal,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add applicant!", err);
      }
    } else {
      try {
        try {
          await editApplicantSchema.validate(formValues, { abortEarly: false });
          const { uidb64, resume, ...updatedFormValues } = formValues;
          if (typeof resume !== "object") {
            dispatch(
              editRecruiter({
                uidb64,
                formValues: updatedFormValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          } else {
            dispatch(
              editRecruiter({
                uidb64,
                formValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          }
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update applicant!", err);
      }
    }
  };

  const filterData = () => {
    setFilterChange(true);
    setIsFilterApplied(true);
  };

  return (
    <div>
      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">First Name</label>
                  <input
                    value={formValues.first_name}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="first_name"
                  />
                  {errors.first_name && (
                    <p className="form-errors">{errors.first_name}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Last Name</label>
                  <input
                    value={formValues.last_name}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="last_name"
                  />
                  {errors.last_name && (
                    <p className="form-errors">{errors.last_name}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Email</label>
                  <input
                    value={formValues.email}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="email"
                    name="email"
                  />
                  {errors.email && (
                    <p className="form-errors">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Phone Number
                  </label>
                  <input
                    value={formValues.phone_number}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="phone_number"
                  />
                  {errors.phone_number && (
                    <p className="form-errors">{errors.phone_number}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Applying for Position
                  </label>
                  <select
                    value={formValues.position}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="position"
                  >
                    <option value="">Select Designation</option>
                    <option value="ASSOCIATE_SOFTWARE_ENGINEER">
                      Associate Software Engineer
                    </option>
                    <option value="SOFTWARE_ENGINEER">Software Engineer</option>
                    <option value="SENIOR_SOFTWARE_ENGINEER">
                      Senior Software Engineer
                    </option>
                    <option value="FULL_STACK_DEVELOPER">
                      Full Stack Developer
                    </option>
                    <option value="BUSINESS_DEVELOPER_EXECUTIVE">
                      Business Developer Executive
                    </option>
                    <option value="BUSINESS_DEVELOPMENT_MANAGER">
                      Business Development Manager
                    </option>
                    <option value="ACCOUNTS_&_ADMIN_EXECUTIVE">
                      Accounts & Admin Executive
                    </option>
                    <option value="HR_MANAGER">HR Manager</option>
                  </select>
                  {errors.position && (
                    <p className="form-errors">{errors.position}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Upload Resume in PDF
                  </label>
                  <input
                    onChange={handleChange}
                    className="w-full px-2 py-1.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md appearance-none overflow-hidden"
                    type="file"
                    accept="application/pdf"
                    name="resume"
                  />
                  {errors.resume && (
                    <p className="form-errors">{errors.resume}</p>
                  )}
                </div>

                {portal === "admin" && (
                  <>
                    <div>
                      <label>Referrals</label>
                      <select
                        value={
                          formValues.referrers === null
                            ? ""
                            : formValues.referrers
                        }
                        onChange={handleChange}
                        className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        name="referrers"
                      >
                        <option value="">Select Referrals</option>
                        {employees
                          ? employees
                              .filter(
                                (employee) =>
                                  employee.employee_status === "WORKING"
                              )
                              .map((emp, index) => (
                                <option key={index} value={emp.id}>
                                  {emp.employee_name}
                                </option>
                              ))
                          : null}
                      </select>
                      {errors.referrers && (
                        <p className="form-errors">{errors.referrers}</p>
                      )}
                    </div>
                    <div>
                      <label>Status</label>
                      <select
                        value={formValues.status}
                        onChange={handleChange}
                        className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        name="status"
                      >
                        <option value="">Select Status</option>
                        <option value="PENDING">Pending</option>
                        <option value="IN_PROCESS">In Process</option>
                        <option value="SCHEDULED">Scheduled</option>
                        <option value="SELECTED">Selected</option>
                        <option value="REJECTED">Rejected</option>
                      </select>
                      {errors.status && (
                        <p className="form-errors">{errors.status}</p>
                      )}
                    </div>
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Interview Date
                      </label>
                      <input
                        value={formValues.interview_date}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="date"
                        name="interview_date"
                      />
                      {errors.interview_date && (
                        <p className="form-errors">{errors.interview_date}</p>
                      )}
                    </div>
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Assigned To
                      </label>
                      <select
                        value={formValues.assigned_to}
                        disabled={portal !== "admin"}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        name="assigned_to"
                      >
                        <option value="">Select </option>
                        {employeeDetailList
                          ? employeeDetailList
                              .filter(
                                (employee) =>
                                  employee.employee_status === "WORKING"
                              )
                              .map((val, index) => (
                                <option key={index} value={val.id}>
                                  {val.employee_name}
                                </option>
                              ))
                          : null}
                      </select>
                      {errors.assigned_to && (
                        <p className="form-errors">{errors.assigned_to}</p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                <div className="flex gap-4">
                  {enable ? (
                    <button
                      className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                      type="submit"
                    >
                      Save
                    </button>
                  ) : (
                    <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                      <RotatingLinesLoader />
                    </div>
                  )}
                  <button
                    type="button"
                    className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                    onClick={closeEditModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </EditModal>

      <DeleteModal handleDelete={handleDelete} />

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
        <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between">
            <div>
              <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                Applicants
              </h5>
            </div>
            <div className="flex flex-col md:flex-row gap-1 items-start md:items-center max-md:mt-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Applicant"
                  value={applicantName}
                  onChange={(e) => setApplicantName(e.target.value)}
                  className="rounded-lg p-2 w-40 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 focus:ring-0 "
                />
              </div>
              <div>
                <select
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                  id="pos"
                  value={appliedPosition}
                  onChange={(e) => {
                    setAppliedPosition(e.target.value);
                  }}
                >
                  <option value="">Select Designation</option>
                  <option value="ASSOCIATE_SOFTWARE_ENGINEER">
                    Associate Software Engineer
                  </option>
                  <option value="SOFTWARE_ENGINEER">Software Engineer</option>
                  <option value="SENIOR_SOFTWARE_ENGINEER">
                    Senior Software Engineer
                  </option>
                  <option value="FULL_STACK_DEVELOPER">
                    Full Stack Developer
                  </option>
                  <option value="BUSINESS_DEVELOPER_EXECUTIVE">
                    Business Developer Executive
                  </option>
                  <option value="BUSINESS_DEVELOPMENT_MANAGER">
                    Business Development Manager
                  </option>
                  <option value="ACCOUNTS_&_ADMIN_EXECUTIVE">
                    Accounts & Admin Executive
                  </option>
                  <option value="HR_MANAGER">HR Manager</option>
                </select>
              </div>
              <div>
                <select
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                  id="sts"
                  value={applicationStatus}
                  onChange={(e) => {
                    setApplicationStatus(e.target.value);
                  }}
                >
                  <option value="">Select Status</option>
                  <option value="PENDING">Pending</option>
                  <option value="IN_PROCESS">In Process</option>
                  <option value="SCHEDULED">Scheduled</option>
                  <option value="SELECTED">Selected</option>
                  <option value="REJECTED">Rejected</option>
                </select>
              </div>
              <div>
                <button
                  type="button"
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={() => filterData()}
                >
                  Apply Filter
                </button>
              </div>
              {isFilterApplied && (
                <div>
                  <button
                    type="button"
                    className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                    onClick={() => ClearFilter()}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Clear Filter
                  </button>
                </div>
              )}
              <div className="max-md:my-2">
                <button
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={handleNew}
                  style={{ marginLeft: "30px" }}
                >
                  Add Applicants
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <Pagination
              data={recruits}
              filterChange={filterChange}
              filterUseEffect={filterUseEffect}
              setRowChange={setRowChange}
              rowChangeUseEffect={rowChangeUseEffect}
              pageChangeUseEffect={pageChangeUseEffect}
              isLoading={recruitsLoading}
              count={count}
              columns={columns}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruits;
